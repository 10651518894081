import { Spinner } from "reactstrap"
const MediumSpinner = () => {
    // return (
    //     <div className='suspense-loader'>
    //     <Spinner size='bg'/>
    //     <span className='ms-50'>Түр хүлээнэ үү...</span>
    // </div>
    // )
    return(
    <div className="suspense-loader">
      <Spinner size={'bg'}>
        Түр хүлээнэ үү...
      </Spinner>
    </div>
    )
  }

export default MediumSpinner