const ComponentSpinner = () => {
  return (
    <div className="fallback-spinner" style={{position: 'fixed', top: '0', left: "0", width: '100%', height: "100%", zIndex: 9999, }}>
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default ComponentSpinner
